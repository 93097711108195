import Vue from 'vue'
import VueRouter from 'vue-router'
import HoVue from '../components/home'
import HomeVue from '../views/home/HomeList.vue'
// import HomeVue from '../views/home/HomeVue.vue'
import product from '../views/product/ProductVue.vue'
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  // 起始页
  {
    path: '/home',
    component: HoVue,
    redirect: '/home',
    children: [
      // 首页
      {
        path: '/home',
        component: HomeVue,
      },
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/HomeList.vue'),
      },
      // 首页详情
      {
        path: '/detailpage',
        component: () => import('../views/home/detailVue.vue'),
      },
      // 登录页
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/LoginVue.vue'),
      },
      // 注册页
      {
        path: '/register',
        name: 'register',
        component: () => import('../views/login/register.vue'),
      },
      // 产品询价
      {
        path: '/product',
        component: product,
      },
      // 产品竞价
      {
        path: '/bidding',
        component: () => import('../views/bidding/BiddingVue.vue'),
      },
      // 产品拍卖
      {
        path: '/auction',
        component: () => import('../views/auctionManage/auctionList.vue'),
      },
      // 集采
      {
        path: '/group',
        component: () => import('../views/group/GroupVue.vue'),
      },
      // 企业
      {
        path: '/company',
        component: () => import('../views/company/CompanyVue.vue'),
      },
      // 行业
      {
        path: '/industry',
        component: () => import('../views/industry/IndustryVue.vue'),
      },
      // 产品询价详情
      {
        path: '/productdetail',
        component: () => import('../views/product/ProductdetailVue.vue'),
      },
      // 产品竞价详情
      {
        path: '/biddingdetail',
        component: () => import('../views/bidding/BiddingdetailVue.vue'),
      },
      // 产品拍卖详情
      {
        path: '/auctionDetail',
        component: () => import('../views/auctionManage/auctionDetail.vue'),
      },
      // 集采产品详情
      {
        path: '/groupdetail',
        component: () => import('../views/group/GroupdetailVue.vue'),
      },
      // 企业详情
      {
        path: '/companydetail',
        component: () => import('../views/company/CompanydetailVue.vue'),
      },
      // 行业详情
      {
        path: '/industrydetail',
        component: () => import('../views/industry/IndustrydetailVue.vue'),
      },
      {
        path: '/search',
        component: () => import('../views/serach/searchPage.vue'),
      },
      {
        path: '/guide',
        component: () => import('../views/guide/guide.vue'),
      },
    ],
  },
]
const router = new VueRouter({
  routes,
  mode: 'history',
})
// 挂载路由导航守卫1
router.beforeEach((to, from, next) => {
  // 将整个页面滚动条回滚到顶部
  window.scrollTo({
    top: 0,
    behavior: 'smooth', // 如果需要平滑滚动
  })
  const token = window.localStorage.getItem('token')
  // 如果没有 token 并且目标路由不是登录页面，则重定向到登录页面
  if (!token && to.path !== '/login' && to.path !== '/register') {
    next({ path: '/login' })
  } else {
    next()
  }
})
export default router
