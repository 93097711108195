<template>
  <div class="topbar">
    <Header></Header>
    <div class="wrap">
    </div>
    <!-- 发布询价 -->
    <div class="model" v-if="enquiryType">
      <div class="enquiry" v-if="enquiryType">
        <i
          style="float: right; margin-top: 8px; cursor: pointer; color: #000; font-size: 20px;"
          class="el-icon-close"
          @click="
            () => {
              enquiryType = false;
            }
          "
        ></i>
        <h1>
          无需注册 <br />
          免费发布采购信息
        </h1>
        <p>坐等优质供应商报价</p>
        <div class="form-input">
          <div class="input-group">
            <input
              placeholder="产品名称"
              id="productName"
              v-model="enquiryData.name"
              @focus="focusInput('productName')"
              @blur="blurInput"
            />
          </div>
          <div class="input-group">
            <input
              placeholder="所属行业"
              id="industry"
              v-model="enquiryData.industryName"
              @focus="focusInput('industry')"
              @blur="blurInput"
            />
          </div>
          <div class="input-group units">
            <input
              @input="handleInput"
              maxLength='11'
              placeholder="采购量"
              id="number"
              v-model="enquiryData.number"
              @focus="focusInput('number')"
              @blur="blurInput"
            />
            <input
              placeholder="单位"
              id="unit"
              v-model="enquiryData.unit"
              @focus="focusInput('unit')"
              @blur="blurInput"
            />
          </div>
          <div class="input-group">
            <input
              oninput="value=value.replace(/[^\d]/g,'')"
              maxLength='11'
              placeholder="联系电话"
              id="phone"
              v-model="enquiryData.phone"
              @focus="focusInput('phone')"
              @blur="blurInput"
            />
          </div>
          <div class="btn">
            <p @click="enquiryFn">立即提交</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 搜索 -->
    </div>
</template>

<script>
import { addEnquiryApi } from "@/api/index";
import Header from '@/components/HeaderVue.vue'
export default {
  components: {Header},
  props: {},
  data () {
    return {
      selectType:1,
      url: "https://zloss.z-srm.com/upload/2023-10-31/a90dfa8c7c54481cb6af98e4dcc38676-file",
      srcList: [
        "https://zloss.z-srm.com/upload/2023-10-31/a90dfa8c7c54481cb6af98e4dcc38676-file  ",
      ],
      enquiryType: false,
      searchData:[
        {
          name:'采购',
          value:1,
        },
        {
          name:'企业',
          value:2,
        },{
          name:'资讯',
          value:3,
        }
      ],
      enquiryData: {
        name: "",
        industryName: "",
        number: "",
        unit: "",
        phone: "",
      },
      select: '1',
      searchInput: ''
    }
  },
  computed: {},
  methods: {
    handleInput() {
      let value = this.enquiryData.number;
      // 过滤非数字和小数点
      value = value.replace(/[^\d.]/g, '');
      // 限制小数点后最多两位
      const parts = value.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        value = parts.join('.');
      }
      // 更新输入框的值
      this.enquiryData.number = value;
    },
    // 我要询价
    // enquiryFn() {
    //   this.enquiryType = true;
    // },
    // 发布询价
    async enquiryFn() {
      if (this.enquiryData.name == '') return this.$message.error('产品名称不能为空!')
      if (this.enquiryData.industryName == '') return this.$message.error('所属行业不能为空!')
      if (this.enquiryData.number == '') return this.$message.error('采购量不能为空!')
      if (this.enquiryData.unit == '') return this.$message.error('单位不能为空!')
      if (this.enquiryData.phone == '') return this.$message.error('联系电话不能为空!')
      await addEnquiryApi(this.enquiryData).then(({ data: res }) => {
        if (res.status === 200) {
          this.$message({
            type: "success", // success error warning
            message: "发布成功",
            duration: 1000,
          });
          this.enquiryType = false
          this.enquiryData.industryName = "";
          this.enquiryData.name = "";
          this.enquiryData.number = "";
          this.enquiryData.phone = "";
          this.enquiryData.unit = "";
        }
      });
    },
    blurInput() {
      this.focusedInput = null;
    },
    requestShowDialog() {
      // 子组件中的按钮点击时触发自定义事件，要求父组件显示弹框
      // this.$emit('showDialogRequest');
      this.enquiryType = true
    },
    tabFn(type){
        this.selectType=type
    },
    // 跳转搜索
    searchFn(){
      if(this.searchInput!==''){
        this.$router.push({
          path: '/search',
          query: {
            type:this.selectType,
            value:this.searchInput
          },
        })
      }else{
        this.$message.error('请输入您要搜索的内容')
      }
    }
  },
  created () {},
  mounted () {}
}
</script>
<style scoped lang="less">
.topbar {
  background: #fff;
  height: 68px;
  color: #fff;
  .search {
    height: 80px;
    margin: 0 auto;
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      h3 {
        margin: 15px 0 0;
        font-size: 32px;
        height: 36px;
      }
      p {
        margin: 10px 0 0;
        font-size: 10px;
      }
      img {
        margin-top: 20px;
        width: 60px;
        height: 60px;
        cursor: pointer;
        margin-right: 16px;
      }
    }
    .middle {
      width: 700px;
      height: 80px;
      .middleSearch {
        position: relative;
        padding: 0 120px 0 80px;
        height: 100px;
        width: 100%;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        overflow: hidden;
        p {
          margin-top: 12px;
          font-size: 14px;
          margin-bottom: 14px;
          span {
            margin-right: 40px;
            color: rgba(0, 0, 0, 0.40);
            cursor: pointer;
          }
          .active{
            color: #F05123;     
          }
        }
        .searchBox /deep/ .el-input__inner {
          border: 1px solid rgba(240, 81, 35, 1);
          border-radius: 4px;
        }
        .searchBtn {
          position: absolute;
          top: 16px;
          right: 122px;
          padding: 0 10px;
          background: rgba(240, 81, 35, 1);
          border: 1px solid #fff;
          color: rgba(255, 255, 255, 1);
          height: 36px;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          font-weight: bold;
          font-family: "SourceHanSancCN";
          line-height: 25px;
          i {
            margin-left: 8px;
            font-weight: bold;
          }
        }
      }
      ul {
        margin-top: 10px;
        margin-left: 50%;
        display: flex;
        justify-content: space-between;
        width: calc(100% - 50px);
        transform: translate(-50%);
        li {
          height: 32px;
          border-radius: 32px;
          cursor: pointer;
          font-size: 14px;
          line-height: 32px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.60);
          img {
            margin-right: 4px;
            width: 32px;
            height: 32px;
            vertical-align: middle;
          }
        }
      }
    }
    .right {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      width: 200px;
      .img {
        width: 123px;
      }
      .showDialogBtn {
        padding: 0 15px;
        background-color: rgba(5, 14, 21, 1);
        border-image: linear-gradient(to right, rgba(250, 141, 59, 1), rgba(240, 81, 35, 1)) 1;
      }
      .showDialogBtn:hover {
        border-image: linear-gradient(to right, rgba(250, 141, 59, .7), rgba(240, 81, 35, .7)) 1;
      }
    }
  }
  .model {
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    .enquiry {
      z-index: 99999;
      width: 435px;
      height: 452px;
      background: #ffffff;
      border-radius: 16px 16px 16px 16px;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
      opacity: 1;
      position: fixed;
      bottom: 22%;
      right: 40%;
      padding: 10px 20px 50px 20px;
      .btn {
        p {
          height: 40px;
          background: linear-gradient(90deg, #FA8D3B 0%, #F05123 100%);
          border-radius: 8px 8px 8px 8px;
          cursor: pointer;
          opacity: 1;
          font-size: 16px;
          font-weight: bold;
          color: #fff;
          line-height: 40px;
        }
        p:hover {
          background-color: rgba(198,49,39, .7);
        }
      }
      h1 {
        padding-top: 20px;
        text-align: center;
        width: 435px;
        height: 68px;
        font-size: 24px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.8);
        line-height: 35px;
        margin: 0;
      }
      p {
        text-align: center;
        // width: 144px;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.6);
        line-height: 30px;
        margin: 0;
      }
      .form-input {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
      }
      .units {
        display: flex;
        input:nth-child(1) {
          margin-right: 10px;
        }
        input {
          width: 100px;
          height: 48px;
        }
      }
      .input-group {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
      input {
        height: 48px;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        flex: 1;
        border: none;
        border: 2px solid transparent;
        background-color: #f6f6f6;
        transition: background-color 0.3s, border-color 0.3s;
        padding: 5px;
        font-size: 16px;
        font-weight: 500;
        padding-left: 15px;
      }
      input:focus {
        border-color: red;
        background-color: white;
      }
    }
  }
  .searchModel{
     width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    .searchDiv{
      z-index: 99999;
      width: 735px;
      height: 152px;
      background: #ffffff;
      border-radius: 16px 16px 16px 16px;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
      opacity: 1;
      position: fixed;
      bottom: 62%;
      right: 30%;
      padding: 10px 20px 50px 20px;
      .input-with-select{
        margin-top: 30px;
        .select{
        }
        /deep/.btn{
          font-size: 16px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          // line-height: 24px;
          background: linear-gradient(90deg, #FA8D3B 0%, #F05123 100%);
        }
      }
    }
  }
}
</style>
