import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import BaiduMap from 'vue-baidu-map'
import VueLazyload from 'vue-lazyload'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/index.css'
import * as echarts from 'echarts'

// 导入思源黑体字体
import './assets/font/font.css'

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.use(Element)
Vue.use(VueLazyload)
Vue.use(BaiduMap, {
  ak: 'Pe3tOpTBZC170zmSticMBtN9h25OorZZ' // 替换为密钥
});
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
