<template>
  <div class='home'>
    <!-- <Header></Header> -->
    <!-- <Topbar></Topbar> -->
    <router-view></router-view>
    <Footer v-if="$route.name!='login'"></Footer>

    <BackToTopButton></BackToTopButton>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue"

import BackToTopButton from '@/components/BackToTopButton.vue'
import Header from '@/components/HeaderVue.vue'
// import Topbar from '@/components/TopbarVue.vue'
export default {
  data() {
    return {
      controlTop: ''
    }
  },
  created() {
    // console.log();
  },
  mounted() {
  },
  components: {
    Header,
    // Topbar,
    BackToTopButton,
    Footer

  },
  methods: {
  }
}
</script>
<style scoped lang='less'>
.home{
  flex: 1;
  background: rgba(241, 244, 246, 1);
  box-sizing: border-box;
}
</style>
