<template>
  <div id="app">
    <router-view v-if="!sysUpdate" />
    <div v-else class="error-page">
      <img width="480" height="400" src="@/assets/img/503.png" alt="">
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  components: {
  },
  props: {},
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['sysUpdate'])
  },
  methods: {
    ...mapActions(['adminLogin']),
  },
  created() { },
  mounted() {
    this.adminLogin()
  }
}
</script>
<style scoped lang="less">
#app {
  background: rgba(241, 244, 246, 1);
  display: flex;
  flex-direction: column;
  height: 100;
  margin: 0;
}


.error-page {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

/deep/.el-picker-panel {
  z-index: 9999 !important;
}
</style>
