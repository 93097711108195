<template>
  <div class="footer">
    <div class="wrap">
      <div class="left">
        <ul>
          <li><img src="../assets/img/logo2.png" alt="" /></li>
          <li>
            <h4>炙讯云</h4>
            <p>打造中国领先的云采购平台</p>
          </li>
        </ul>
        <!-- <h2>平台客服热线：13065893313</h2>
        <a href="https://beian.miit.gov.cn">浙ICP备2023005044号</a>
        <span>浙公网安备 11010102003852号</span> -->
      </div>
      <!-- <div class="center">
        <ul>
          <li>交易指南</li>
          <li @click="handleClickGuide">交易流程</li>
          <li @click="handleClickGuide">支付方式</li>
          <li @click="handleClickGuide">合同签署</li>
          <li @click="handleClickGuide">发票说明</li>
        </ul>
        <ul>
          <li>采购商</li>
          <li @click="handleClickGuide">发布询价</li>
          <li @click="handleClickGuide">管理询价单</li>
          <li @click="handleClickGuide">管理采购订单</li>
          <li @click="handleClickGuide">管理私域企业</li>
        </ul>
        <ul>
          <li>供应商</li>
          <li @click="handleClickGuide">如何询价报价</li>
          <li @click="handleClickGuide">如何竞价报价</li>
          <li @click="handleClickGuide">管理报价单</li>
          <li @click="handleClickGuide">管理销售订单</li>
          <li @click="handleClickGuide"><pre>管理私域企业 </pre></li>
        </ul>
        <ul>
          <li>平台规则</li>
          <li @click="handleClickGuide($event, '入驻流程')">企业入驻与协议</li>
          <li @click="handleClickGuide">交易规范</li>
          <li @click="handleClickGuide($event, '买家交易规则')">交易规则</li>
          <li @click="handleClickGuide">隐私条款</li>
        </ul>
      </div> -->
      <div class="middle">
        <ul>
          <li @click="handleClickGuide($event, '入驻流程')">企业入驻与协议</li>
          <li @click="handleClickGuide">交易规范</li>
          <li @click="handleClickGuide($event, '买家交易规则')">交易规则</li>
          <li @click="handleClickGuide">隐私条款</li>
        </ul>
        <a href="https://beian.miit.gov.cn">浙ICP备2023005044号</a>
        <span>浙公网安备 11010102003852号</span>
        <p>平台客服热线：13065893313</p>
      </div>
      <div class="right">
        <div>
          <img
            src="../assets/img/qrLogo.jpg"
            alt=""
            style="margin-left: 8px; width: 80px; height: 80px; border-radius: 4px"
          />
        </div>
        <p>炙讯云微信公众号</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  methods: {
    handleClickGuide(e, hash) {
      // 暂时不让点击
      return false
      let guideHash = e.target.innerText
      if (hash) {
        guideHash = hash
      }
      this.$router.push({
        path: `guide#${guideHash}`,
      })
    },
  },
  created() {},
  mounted() {},
}
</script>
<style scoped lang="less">
.footer {
  position: relative;
  height: 150px;
  background-color: #fff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;

  // width: 100vw;
  .wrap {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .left {
      margin-top: 40px;
      width: 256px;
      height: 100px;

      ul {
        display: flex;
        align-items: center;

        li {
          h4 {
            width: 54px;
            height: 25px;
            font-size: 18px;
            font-weight: bold;
            color: rgba(0, 0, 0, 1);
            line-height: 21px;
            margin: 0;
          }

          p {
            width: 144px;
            height: 17px;
            font-size: 12px;
            font-weight: bold;
            color: rgba(0, 0, 0, 1);
            line-height: 14px;
          }

          img {
            width: 48px;
            height: 48px;
            margin-right: 10px;
          }
        }
      }

      h2 {
        width: 256px;
        height: 25px;
        font-size: 18px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.8);
        line-height: 21px;
      }

      a {
        color: rgba(0, 0, 0, 0.4);
        font-size: 13px;
        font-weight: bold;
      }

      span {
        display: block;
        color: rgba(0, 0, 0, 0.4);
        font-size: 13px;
        font-weight: bold;
      }
    }

    // .center {
    //   margin-top: 40px;
    //   display: flex;
    //   justify-content: space-around;
    //   flex: 1;

    //   ul {
    //     li:nth-child(1) {
    //       width: 56px;
    //       height: 20px;
    //       font-size: 14px;
    //       font-weight: bold;
    //       color: rgba(0, 0, 0, 0.8);
    //       line-height: 16px;
    //     }

    //     li {
    //       margin-bottom: 20px;
    //       height: 20px;
    //       font-size: 14px;
    //       font-weight: bold;
    //       color: rgba(0, 0, 0, 0.4);
    //       line-height: 16px;
    //       cursor: pointer;
    //       pre {
    //         word-break: break-all;
    //         text-wrap: wrap;
    //         font-family: inherit;
    //       }
    //     }

    //     li:hover {
    //       color: rgba(0, 0, 0, 0.8);
    //     }
    //   }
    // }
    .middle {
      margin-top: 30px;
      flex: 1;
      ul {
        display: flex;
        li {
          margin-right: 60px;
          font-size: 16px;
          font-weight: bold;
        }
      }
      a, span {
        margin-right: 12px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.40);
        font-weight: bold;
      }
      p {
        color: rgba(0, 0, 0, 0.80);
        font-size: 12px;
        font-weight: bold;
      }
    }
    .right {
      width: 200px;
      margin-top: 20px;
      p {
        width: 96px;
        height: 17px;
        font-size: 12px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.6);
        line-height: 14px;
        text-align: center;
      }
    }
  }
}
</style>
