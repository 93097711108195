<script>
export default {
  name: 'AddressSelector',
  props: {
    addressTree: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedArr: {
      type: Array,
      default: () => [],
    },
    // 节点index
    addressIndex: {
      type: Number,
      required: false,
      default: 0,
    },
    // label显示键名
    labelName: {
      type: String,
      required: false,
      default: 'name',
    },
    // value值键名
    valueName: {
      type: String,
      required: false,
      default: 'code',
    },
    // childName 子节点列表键名
    childName: {
      type: String,
      required: false,
      default: 'childList',
    },
    // childUnlimited 有子节点时是否 补充子节点不限
    childUnlimited: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      selectedAddress: { [this.valueName]: '' },
      selectedHidden: false,
      addressTreeData: [],
      addressExpand: false,
      // todo 动态计算显示个数
      visibleCount: 7,
    }
  },
  computed: {
    addressVisible() {
      return this.addressTree.slice(0, this.visibleCount)
    },
    addressHidden() {
      return this.addressTree.slice(this.visibleCount)
    },
  },

  methods: {
    // 选择地址本级状态更新/渲染子级/通知父级
    selectAddress(v, type) {
      this.$set(this.selectedArr, this.addressIndex, v)
      this.selectedAddress = { [this.valueName]: '' }
      this.$nextTick(() => {
        this.selectedAddress = v
        this.addressExpand = false
        if (type === 'hidden') {
          this.selectedHidden = true
        } else {
          this.selectedHidden = false
        }
        this.change(this.selectedArr, this.addressIndex)
      })
    },

    /**
     * @params1 selectedArr 根节点输出code数组 [10000,10100...]
     * @params2 addressIndex 选择节点index
     * @params3 resLabel 根节点输出label数组 ['北京','北京市'...]
     * @return (selectedArr, addressIndex, resLabel)
     */
    change(selectedArr = [], addressIndex = 0) {
      let res = []
      let resLabel = []
      if (!this.addressIndex) {
        for (const i of this.selectedArr) {
          if (i[this.valueName] === '') break
          res.push(i[this.valueName])
          resLabel.push(i[this.labelName])
        }
        res = res.slice(0, addressIndex + 1)
        resLabel = resLabel.slice(0, addressIndex + 1)
        console.log(this.selectedArr)
        console.log(addressIndex)
        // 没有子节点添加一个不限
        if (this.childUnlimited) {
          if (
            this.selectedArr[addressIndex][this.childName] &&
            this.selectedArr[addressIndex][this.childName]?.length > 0
          ) {
            resLabel.push('不限')
          }
        }
      }
      this.$emit('change', res, addressIndex, resLabel)
    },
    resetSelector() {
      this.selectAddress({ code: '' })
    },
  },
}
</script>

<template>
  <div>
    <div class="a-select">
      <p class="a-select-title">{{ addressIndex ? '' : '收货地址：' }}</p>
      <div class="a-select-content">
        <ul>
          <li
            @click="selectAddress({ code: '' })"
            :class="selectedAddress[valueName] === '' ? 'black' : ''"
          >
            不限
          </li>
          <li
            v-for="(address, index) in addressVisible"
            :key="index"
            @click="selectAddress(address)"
            :class="
              selectedAddress[valueName] === address[valueName] ? 'black' : ''
            "
          >
            {{ address[labelName] }}
          </li>
          <el-popover
            trigger="manual"
            v-model="addressExpand"
            placement=""
            width="650"
            popper-class="el-popover-custom"
          >
            <ul @click="addressExpand = false">
              <li
                v-for="(address, index) in addressHidden"
                :key="index"
                @click="selectAddress(address, 'hidden')"
                :class="
                  selectedAddress[valueName] === address[valueName]
                    ? 'black'
                    : ''
                "
              >
                {{ address[labelName] }}
              </li>
            </ul>
            <li
              slot="reference"
              @click="addressExpand = !addressExpand"
              :class="selectedHidden ? 'black' : ''"
              v-if="addressTree.length > visibleCount"
            >
              <span>{{
                selectedHidden
                  ? selectedAddress[labelName]
                    ? selectedAddress[labelName]
                    : '更多'
                  : '更多'
              }}</span>
              <i
                :class="
                  addressExpand ? 'el-icon-caret-top' : 'el-icon-caret-bottom'
                "
              ></i>
            </li>
          </el-popover>
        </ul>
      </div>
    </div>
    <AddressSelector
      v-if="selectedAddress[childName] && selectedAddress[childName].length > 0"
      :addressTree="selectedAddress[childName]"
      @change="change"
      :selectedArr="selectedArr"
      :addressIndex="addressIndex + 1"
    ></AddressSelector>
  </div>
</template>

<style scoped lang="less">
@selectPrefixCls: a-select;
.@{selectPrefixCls} {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  line-height: 16px;
  margin-top: 10px;
  &-title {
    width: 70px;
  }
  &-content {
    display: flex;
    /deep/ .el-popover-custom {
      transform: translate(-570px, 30px);
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: start;
      flex-wrap: wrap;
      li {
        min-width: 52px;
        height: 24px;
        margin-right: 10px;
        padding: 0 5px;
        cursor: pointer;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .black {
      background: rgba(24, 144, 255, 1);
      color: white;
      font-size: 14px;
      border-radius: 6px;
      text-align: center;
    }
  }
}
.el-popover {
  ul {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    li {
      min-width: 52px;
      height: 24px;
      margin-right: 10px;
      padding: 0 5px;
      cursor: pointer;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .black {
    background: rgba(24, 144, 255, 1);
    color: white;
    font-size: 14px;
    border-radius: 6px;
    text-align: center;
  }
}
</style>
