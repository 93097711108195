<!-- 产品询价 -->
<template>
  <div class="ProductInquiry">
    <Topbar></Topbar>
    <div class="wrap">
      <div class="leftBox">
        <h1>产品询价列表</h1>
        <div class="search">
          <div class="top">
            <p>搜索结果：</p>
            <div>
              <template v-for="(tag, index) in tags">
                <el-tag
                  v-if="tag !== ''"
                  :key="index"
                  closable
                  :disable-transitions="true"
                  @close="removeTag(tag[0])"
                >
                  {{ tag[1] }}
                </el-tag>
              </template>
              <p class="clear" @click="removeAllTag">
                重置
                <i
                  class="el-icon-brush"
                  style="
                    transform: rotate(225deg);
                    font-size: 12px;
                    margin-left: 3px;
                  "
                ></i>
              </p>
            </div>
          </div>
          <div class="middle">
            <p>发布日期：</p>
            <ul>
              <li @click="dateFn(0)" :class="dateValue === 0 ? 'one' : ''">
                不限
              </li>
              <li
                v-for="(idiom, index) in dateArr"
                :key="index"
                @click="dateFn(idiom.value)"
                :class="dateValue === idiom.value ? 'one' : ''"
              >
                {{ idiom.label }}
              </li>
            </ul>
            <el-date-picker
              class="date-class"
              v-model="dateValues"
              type="daterange"
              @change="handleDateChange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
          <AddressSelector
            :addressTree="idioms"
            @change="handleLocationChange"
            ref="addressSelector"
          ></AddressSelector>
        </div>
        <section v-loading="loading" element-loading-background="transparent">
          <h5 class="count">
            为您找到<span style="color: rgba(240, 81, 35, 1)">{{
              totalNum
            }}</span
            >条采购信息
          </h5>
          <div class="allLabel">
            <div class="labelBox" v-for="(item, index) in enquiryList" :key="index" @click="goDetail(item)">
              <div class="labelLeft">
                <img :src="item.imgUrl === null ? require('../../assets/img/noImg.png') : item.imgUrl" alt="">
              </div>
              <div class="labelMiddle">
                <h4 class="h4Class textHidden2">
                  <p>{{ item.productName }}</p>
                  <p><span>单位: <i>{{ item.productUnit }}</i></span><span>单次采购数量: <i>{{ item.number }}</i></span></p>
                </h4>
                <p>询价标题: {{ item.name == null ? '暂无' : item.name }}</p>
                <div class="mainContent">
                  <div class="mainBox">
                    <div class="mainImg"><img src="../../assets/img/index/index1.png" alt=""></div>
                    <div><p>发票要求</p><span>{{item.invoiceType == 1 ? '不需要发票' : (item.invoiceType == 2 ? '增值税普通发票' : '增值税专用发票')}}</span></div>
                  </div>
                  <div class="mainBox">
                    <div class="mainImg"><img src="../../assets/img/index/index2.png" alt=""></div>
                    <div><p>承运方</p><span>{{item.carrier == 1 ? '需方' : '供方'}}</span></div>
                  </div>
                  <div class="mainBox">
                    <div class="mainImg"><img src="../../assets/img/index/index3.png" alt=""></div>
                    <div>
                      <p>支付方式</p>
                      <el-tooltip class="item" effect="dark" :content="item.payType == 1 ? '款到发货' : (item.payType == 2 ? '定金' + item.subscriptionPer + '%, 发货前结尾款' : (item.payType == 3 ? '定金' + item.subscriptionPer + '%, 发货后尾款' : (item.payType == 4 ? '账期: ' + item.accountTime + '天' : (item.payType == 5 ? '货到付款' : '-'))))" placement="bottom">
                        <span class="textHidden">{{item.payType == 1 ? '款到发货' : (item.payType == 2 ? '定金' + item.subscriptionPer + '%, 发货前结尾款' : (item.payType == 3 ? '定金' + item.subscriptionPer + '%, 发货后尾款' : (item.payType == 4 ? '账期: ' + item.accountTime + '天' : (item.payType == 5 ? '货到付款' : '-'))))}}</span>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="mainBox">
                    <div class="mainImg"><img src="../../assets/img/index/index4.png" alt=""></div>
                    <div>
                      <p>收货地址</p>
                      <span>{{(item.addressProvinceName == null ? '-' : item.addressProvinceName) + (item.addressCityName == null ? '' : item.addressCityName) + (item.addressAreaName == null ? '' : item.addressAreaName)}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="labelRight">
                <div class="rightShow">
                  <p>报价倒计时</p>
                  <span>{{item.countDown}}</span>
                </div>
                <el-button class="lightDeepStyle">立即报价</el-button>
                <p>报价企业: <span>{{item.offerComCount}}</span>家</p>
              </div>
            </div>
          </div>
          <div v-if="apiLisrData.current < totalPage" @click="getEnquiryList(true)" class="jumpBox">更多询价</div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>

import Topbar from '@/components/TopbarVue.vue'
import { askPriceShopListApi, addressApi } from '@/api/index'
import { mixins } from '../../mixin/mixin'
import AddressSelector from '@/components/AddressSelector'
export default {
  components: { Topbar, AddressSelector },
  mixins: [mixins],
  props: {},
  data() {
    return {
      loading: true,
      token: window.localStorage.getItem('token'),
      totalNum: 0,
      totalPage: 1,
      // 询价列表接口参数
      apiLisrData: {
        name: '',
        proName: '',
        state: '2',
        industryId: '', 
        addressProvinceId: '',
        addressCityId: '',
        addressAreaId: '',
        current: 1,
        size: 8,
        timeType: 0,
        beginTime: '',
        endTime: '',
        type: 1,
        round: '',
      },
      enquiryList: [],
      titleStyleObj: {
        width: '51px',
        height: '20px',
        fontSize: '12px',
        color: 'rgb(200, 193, 193)',
        // textAlign: 'center'
      },
      contentStyleObj: {
        width: '101px',
        height: '20px',
        fontSize: '14px',
        color: 'black',
        // textAlign: 'center'
      },
      // 时间数组
      dateArr: [
        { label: '本周', value: 1 },
        { label: '上周', value: 2 },
        { label: '本月', value: 3 },
        { label: '上月', value: 4 },
      ],
      // 收货地址数组[{address Obj}]
      idioms: [],
      // 选择
      selectedAddressArrLabel: [],
      // 搜索日期区间
      dateValue: 0,
      dateValues: null,
    }
  },
  computed: {
    tags() {
      let map = new Map()
      let res = []
      if (this.dateValue > 0) {
        this.dateArr.map((v) => {
          if (v.value == this.dateValue) {
            map.set('dateValue', v.label)
          }
        })
      } else {
        if (this.apiLisrData.beginTime) {
          map.set(
            'dateValue',
            this.apiLisrData.beginTime.substring(0, 10) +
              ' 至 ' +
              this.apiLisrData.endTime.substring(0, 10)
          )
        }
      }
      if (this.selectedAddressArrLabel.length > 0) {
        map.set('selectedAddress', this.selectedAddressArrLabel.join('/'))
      }
      map.forEach((v, k) => {
        res.push(v)
      })
      return map
    },
  },
  methods: {
    // 路由跳转
    routerJump(id) {
      this.$router.push({ path: '/productdetail', query: { id: id } })
    },
    async getAddress() {
      await addressApi().then(({ data: res }) => {
        this.idioms = res.result.list
        // this.removeEmptyChildLists(this.idioms)
        // 创建一个包含省份顺序的数组
        const order = ['福建省', '广东省', '安徽省', '江苏省', '上海', '浙江省']
        // 根据顺序对数据进行排序
        this.idioms.sort((a, b) => order.indexOf(b.name) - order.indexOf(a.name))
      })
    },
    // 修改日期组件背景色
    handleDateChange(value) {
      // 在 change 事件回调中修改背景色
      this.changeBackgroundColor()
      // Convert the date strings to Date objects
      if (value != null) {
        this.dateValue = null
        this.apiLisrData.timeType = 0
        const beginDate = new Date(value[0])
        const endDate = new Date(value[1])
        beginDate.setUTCHours(0, 0, 0, 0)
        endDate.setUTCHours(23, 59, 59, 999)
        beginDate.setDate(beginDate.getDate() + 1)
        endDate.setDate(endDate.getDate() + 1)
        this.apiLisrData.beginTime = beginDate
          .toISOString()
          .replace('T', ' ')
          .slice(0, 19)
        this.apiLisrData.endTime = endDate
          .toISOString()
          .replace('T', ' ')
          .slice(0, 19)
      } else {
        this.resetDatePicker()
      }
      this.getEnquiryList()
    },
    changeBackgroundColor() {
      const datePickerInput = document.querySelector(
        '.ProductInquiry .date-class'
      )
      if (this.dateValues !== null) {
        if (datePickerInput) {
          datePickerInput.style.color = 'black'
        }
      } else {
        if (datePickerInput) {
          datePickerInput.style.color = 'black'
        }
      }
    },
    // 地址切换
    handleLocationChange(selectedValuesCode, index, selectedValuesLabel) {
      this.apiLisrData.addressProvinceId = selectedValuesCode[0] === undefined ? '' : selectedValuesCode[0]
      this.apiLisrData.addressCityId = selectedValuesCode[1] === undefined ? '' : selectedValuesCode[1]
      this.apiLisrData.addressAreaId = selectedValuesCode[2] === undefined ? '' : selectedValuesCode[2]
      this.getEnquiryList()
      // tag栏
      this.selectedAddressArrLabel = selectedValuesLabel
    },
    // 日期切换
    dateFn(name, refresh = 1) {
      this.apiLisrData.timeType = name
      this.apiLisrData.beginTime = ''
      this.apiLisrData.endTime = ''
      this.dateValue = name
      this.dateValues = null
      const datePickerInput = document.querySelector(
        '.ProductInquiry .date-class'
      )
      datePickerInput.style.color = '#000'
      if (refresh) {
        this.getEnquiryList()
      }
    },
    // 获取询价列表
    async getEnquiryList(pushOrder) {
      if (pushOrder !== true) {
        this.apiLisrData.current = 1
      } else if (this.apiLisrData.current < this.totalPage) {
				this.apiLisrData.current = this.apiLisrData.current + 1
			}
      this.apiLisrData.type = 1
      const { data: res } = await askPriceShopListApi(this.apiLisrData)
      // 获取表单数据
      if (pushOrder === true) {
        res.result.records.forEach(item => {
          this.enquiryList.push(item)
        })
      } else {
        this.enquiryList = res.result.records
      }
      this.totalNum = res.result.total
      this.totalPage = res.result.pages
      this.loading = false
    },
    // 跳转报价
    goDetail(result) {
      this.$router.push({
        path: '/productdetail',
        query: {
          id: result.enquiryId,
          proId: result.id
        },
      })
    },

    removeTag(tag) {
      if (tag === 'dateValue') {
        this.resetDatePicker()
      } else {
        this.resetAddressPicker()
      }
    },
    removeAllTag() {
      this.resetDatePicker(0)
      this.resetAddressPicker()
    },
    resetDatePicker(refresh = 1) {
      this.dateFn(0, refresh)
    },
    resetAddressPicker() {
      this.$refs['addressSelector'].resetSelector()
    },
  },
  created() {
    this.getAddress()
    this.getEnquiryList()
  },
  mounted() {},
}
</script>
<style scoped lang="less">
.ProductInquiry {
  background: rgba(241, 244, 246, 1);
  flex: 1;
  .el-input__inner {
    width: 240px;
    height: 24px;
  }
  /deep/.el-date-editor .el-range__icon {
    line-height: 3px;
  }
  /deep/.el-date-editor .el-range__close-icon {
    line-height: 3px;
  }
  /deep/.el-date-editor .el-range-separator {
    height: auto;
  }
  .wrap {
    display: flex;
    .leftBox {
      width: 880px;
      height: 100%;
      margin-right: 20px;
      h1 {
        margin: 36px 0 20px;
        font-size: 24px;
        font-weight: bold;
      }
      .search {
        font-weight: bold;
        .top {
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          div {
            display: flex;
            justify-items: center;
            align-items: center;
          }
          .el-tag {
            background-color: rgba(0, 0, 0, 0.05);
            border-color: #d9ecff;
            height: 24px;
            padding: 0 10px;
            line-height: 24px;
            font-size: 12px;
            border-width: 0;
            color: rgba(0, 0, 0, 0.8);
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            margin-right: 20px;
            white-space: nowrap;
            p {
              font-size: 12px;
            }
          }
          p {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);
          }
          /deep/ .el-tag__close::before {
            color: rgba(0, 0, 0, 0.8);
          }
          /deep/ .el-tag__close {
            color: rgba(0, 0, 0, 0.8);
          }
          /deep/ .el-tag__close:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }
          .clear {
            padding: 3px 10px;
            border-radius: 4px;
            cursor: pointer;
            background-color: #fff;
            font-size: 12px;
            font-weight: bold;
          }
        }
        .middle {
          display: flex;
          align-items: center;
          .one {
            background-color: rgba(24, 144, 255, 1);
            color: white;
            font-size: 14px;
            border-radius: 6px;
            text-align: center;
            // padding: 4px 8px;
          }
          ul {
            display: flex;
            align-items: center;
            li {
              width: 62px;
              height: 24px;
              margin-right: 10px;
              cursor: pointer;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.6);
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          p {
            font-size: 14px;
            font-weight: bold;
            color: #000000;
            line-height: 16px;
          }
        }
        .down {
          /deep/.el-input__inner {
            height: 24px;
          }
          p {
            font-size: 14px;
            font-weight: bold;
            color: #000000;
            line-height: 16px;
            padding-top: 3px;
          }
          align-items: top;
          display: flex;
          margin-top: 15px;
          .address-wrapper {
            ul {
              display: flex;
              align-items: center;
              justify-content: start;
              flex-wrap: wrap;
              li {
                min-width: 62px;
                height: 24px;
                margin-right: 10px;
                padding: 0 5px;
                cursor: pointer;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.6);
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: scroll-y;
              }
            }
            ul :first-child {
              padding: 0;
            }
            .one {
              background-color: rgba(24, 144, 255, 1);
              color: white;
              font-size: 14px;
              border-radius: 6px;
              text-align: center;
              // padding: 4px 8px;
            }
          }
          .black {
            /deep/ .el-input__inner {
              background-color: rgba(24, 144, 255, 1);
              color: white;
              font-size: 14px;
              border-radius: 6px;
              text-align: center;
              // padding: 4px 8px;
            }
            /deep/ input::-webkit-input-placeholder {
              -webkit-text-fill-color: white;
            }
            /deep/ .el-icon-arrow-down::before {
              content: '\e790';
              color: white;
            }
          }
        }
      }
      section {
        width: 1200px;
        .count {
          margin-top: 42px;
          margin-bottom: 20px;
          width: 203px;
          height: 22px;
          font-size: 16px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.60);
          line-height: 19px;
          span {
            color: rgba(0, 0, 0, 0.8);
            padding: 0 5px;
            font-weight: bold;
          }
        }
        .allLabel {
          width: 1200px;
          min-height: 277px;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          .labelBox {
            margin-bottom: 24px;
            padding: 16px;
            display: flex;
            height: 144px;
            width: 100%;
            border-radius: 16px;
            background-color: #fff;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            border: 1px solid transparent;
            cursor: pointer;
            .labelLeft {
              margin-right: 16px;
              width: 112px;
              height: 112px;
              border-radius: 16px;
              img {
                width: 112px;
                height: 112px;
                border-radius: 16px;
              }
            }
            .labelMiddle {
              margin-right: 24px;
              width: calc(100% - 372px);
              .h4Class {
                margin: 2px 0 18px;
                display: flex;
                justify-content: space-between;
                p {
                  font-size: 18px;
                  color: rgba(0, 0, 0, 0.8);
                  font-weight: bold;
                  line-height: 18px;
                  span {
                    margin-left: 60px;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.60);
                    i {
                      color: rgba(24, 144, 255, 1);
                    }
                  }
                }
                
              }
              >p {
                margin-bottom: 12px;
                color: rgba(0, 0, 0, 0.60);
                font-size: 14px;
                line-height: 14px;
                font-weight: bold;
              }
              .mainContent {
                display: flex;
                .mainBox {
                  margin-bottom: 24px;
                  display: flex;
                  width: 20%;
                  .mainImg {
                    margin-right: 9px;
                    width: 40px;
                    height: 40px;
                    background-color: rgba(24, 144, 255, 0.10);
                    border-radius: 8px;
                    img {
                      margin: 8px;
                      width: 24px;
                      height: 24px;
                    }
                  }
                  >div {
                    p {
                      color: rgba(0, 0, 0, 0.40);
                      font-size: 12px;
                      font-weight: bold;
                    }
                    span {
                      margin-top: 2px;
                      display: block;
                      font-size: 14px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.80);
                    }
                  }
                }
                .mainBox:nth-child(1) {
                  width: 25%;
                }
                .mainBox:nth-child(3) {
                  >div span {
                    width: 100px;
                  }
                }
                .mainBox:nth-child(4) {
                  width: 35%;
                }
              }
              
            }
            .labelRight {
              position: relative;
              display: flex;
              align-items: center;
              padding-left: 24px;
              width: 244px;
              border-left: 1px solid rgba(0, 0, 0, 0.10);
              box-sizing: border-box;
              -moz-box-sizing: border-box;
              .rightShow {
                padding: 22px 0;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                align-items: center;
                width: 122px;
                height: 96px;
                background-size: 100% 100%;
                background-image: url(../../assets/img/index/index5.png);
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                p {
                  width: 100%;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.40);
                  text-align: center;
                }
                span {
                  font-size: 13px;
                  font-weight: bold;
                  color: rgba(24, 144, 255, 1);
                  text-align: center;
                }
              }
              .el-button {
                margin-left: 24px;
                padding: 11px 12px;
                width: 104px;
                height: 40px;
                font-size: 14px;
              }
              >p {
                position: absolute;
                top: 76px;
                right: 10px;
                color: rgba(0, 0, 0, 0.40);
                font-size: 12px;
                font-weight: bold;
                span {
                  color: rgba(24, 144, 255, 1);
                }
              }
            }
          }
          .labelBox:hover {
            box-shadow: 4px 4px 16px 0px rgba(0,0,0,0.1);
            border: 1px solid #1890FF;
          }
        }
        .jumpBox {
          margin: 0 0 20px;
          width: 1200px;
          height: 32px;
          background: #FFFFFF;
          border-radius: 16px 16px 16px 16px;
          border: 1px solid transparent;
          cursor: pointer;
          text-align: center;
          line-height: 32px;
          color: rgba(0, 0, 0, 0.40);
          font-size: 14px;
          font-weight: bold;
        }
        .jumpBox:hover {
          border: 1px solid rgba(24, 144, 255, 1);
          color: rgba(24, 144, 255, 1);
        }
      }
    }
  }
}
</style>
