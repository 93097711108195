<template>
  <div>
    <div class="back-to-top-button">
      <div>
        <img @click="requestShowDialog" class="robot" src="../assets/img/index/index6.png">
        <img @click="showPictrue = true" style="margin-left: 6px; width: 24px; height: 24px"
          src="../assets/img/index/wxIcon.jpg" alt="">
        <el-dialog :visible.sync="showPictrue" width="440px" border-radios="16px">
          <img style="width: 400px;" src="../assets/img/index/wxIcon.jpg" alt="">
          <!-- 这里放置其他图片 -->
        </el-dialog>
      </div>
      <div @click="scrollToTop">
        <img style="width: 32px; height: 32px" src="../assets/img/sup.png" alt="" />
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" :show-close="false" :visible.sync="enquiryType" class="fastBox"
      style="z-index: 300;margin-top: 10px;">
      <header class="header">
        <div :class="typeFn == 0 ? 'on' : ''" @click="typeTag(0)">
          单商品询价
        </div>
        <div :class="typeFn == 1 ? 'on' : ''" @click="typeTag(1)">
          模版询价
        </div>
      </header>
      <el-form v-if="typeFn == 0" style="height: 720px;" :model="enquiryData" :rules="rules" ref="enquiryData"
        label-width="100px" class="demo-enquiryData">
        <el-form-item class="formItem" label="询价标题" prop="title">
          <el-input v-model="enquiryData.title" placeholder="输入"></el-input>
        </el-form-item>
        <el-row type="flex" :gutter="20">
          <el-col :span="8">
            <el-form-item class="formItem" label="详细地址" prop="address">
              <el-input v-model="enquiryData.address" placeholder="输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="formItem" label="联系人" prop="contacts">
              <el-input v-model="enquiryData.contacts" placeholder="输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="formItem" label="联系方式" prop="phone">
              <el-input v-model="enquiryData.phone" placeholder="输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <el-form-item class="formItem" label="承运方" prop="carrier">
              <el-radio v-model="enquiryData.carrier" label="2">供方</el-radio>
              <el-radio v-model="enquiryData.carrier" label="1">需方</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item class="formItem" label="发票要求" prop="invoiceType">
              <el-radio v-model="enquiryData.invoiceType" label="3">增值税专用发票</el-radio>
              <el-radio v-model="enquiryData.invoiceType" label="2">增值税普通发票</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <el-form-item class="formItem" label="询价截止" prop="endTime" required>
              <el-date-picker type="date" value-format="yyyy-MM-dd" format="yyyy 年 MM 月 dd 日" placeholder="截止日期"
                v-model="enquiryData.endTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item class="formItem" label="配送方式" prop="deliveryType">
              <el-radio v-model="enquiryData.deliveryType" label="0">物流</el-radio>
              <el-radio v-model="enquiryData.deliveryType" label="2">自提</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="formItem" label="结算方式" prop="payMethod">
          <el-radio @change="payMethodFn(5)" v-model="enquiryData.payMethod" label="5">货到付款</el-radio>
          <el-radio @change="payMethodFn(1)" v-model="enquiryData.payMethod" label="1">款到发货</el-radio>
          <el-radio v-model="enquiryData.payMethod" @change="payMethodFn(2)" label="2">
            先定金
            <el-input v-if="paytypeVal == 2" class="earnest" style="max-width: 90px;"
              v-model="enquiryData.subscriptionPer">
              <i slot="suffix">%</i>
            </el-input>
            ,发货前尾款
          </el-radio>
          <el-radio @change="payMethodFn(3)" v-model="enquiryData.payMethod" label="3">先定金
            <el-input v-if="paytypeVal == 3" class="earnest" style="max-width: 90px;"
              v-model="enquiryData.subscriptionPer">
              <i slot="suffix">%</i>
            </el-input>
            ,发货后尾款</el-radio>
          <el-radio @change="payMethodFn(4)" v-model="enquiryData.payMethod" label="4">账期
            <el-input v-if="paytypeVal == 4" class="earnest" style="max-width: 90px;"
              v-model="enquiryData.subscriptionPer">
              <i slot="suffix">天</i>
            </el-input>
          </el-radio>
        </el-form-item>
        <el-form-item class="formItem" label="询价商品名称" prop="productName">
          <el-input v-model="enquiryData.productName" placeholder="输入"></el-input>
        </el-form-item>
        <el-row type="flex" :gutter="20">
          <el-col :span="5">
            <el-form-item class="formItem" label="商品分类" prop="productType">
              <el-cascader class="datestyle" ref="refHand" :options="industryList" :props="defaultData" clearable
                v-model="enquiryData.productType" filterable placeholder="选择"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item class="formItem" label="商品单位" prop="productUnit">
              <el-input v-model="enquiryData.productUnit" placeholder="输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item class="formItem" label="采购数量" prop="productNum">
              <el-input v-model="enquiryData.productNum" placeholder="输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item class="formItem" label="商品规格" prop="productSpecs">
              <el-input v-model="enquiryData.productSpecs" placeholder="输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item class="formItem" label="商品型号" prop="productModel">
              <el-input v-model="enquiryData.productModel" placeholder="输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item style="margin-bottom: 20px;" class="formItem" label="补充说明" prop="quality">
          <el-input type="textarea" :rows="2" v-model="enquiryData.quality" placeholder="输入"></el-input>
        </el-form-item>
        <el-form-item>
          <template slot="label" style="height: 30px;">
            上传图片：
            <span style="color: rgba(0,0,0,0.4);font-size: 12px;">请上传JPG,PNG,SVG,GIF格式，最多支持上传3张</span>
          </template>
          <el-upload ref="upload" :on-exceed="changeExceed" :headers="headers" :action="uploadUrls" list-type="picture-card"
            :on-preview="handlePictureCardPreview" :on-success="uploadSuccess" :before-remove="beforeRemove"
            :file-list="pictureFileList" class="upload-container" :limit="3" :on-remove="handleRemove">
            <i v-if="pictureFileList.length < 3" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <el-form v-else :model="enquiryData" :rules="rules" ref="enquiryData" label-width="100px"
        class="demo-enquiryData">
        <el-form-item label="上传文件" style="margin-bottom: 30px;" prop="files">
          <el-upload class="upload-demo":headers="headers" drag :action="uploadUrls" :on-preview="handleFileCardPreview"
            :on-success="uploadSuccess" :before-remove="beforeRemove" :on-remove="handleRemove" :limit="1" multiple>
            <div style="margin-bottom: 20px">
              <i class="el-icon-upload"></i>
              <p style="margin-bottom: 5px">上传文件</p>
              <p style="margin: 10px 0;">{{ enquiryData.files.length }}/1</p>
              <p style="z-index: 100; font-size: 12px;">目前仅支持excel、pdf文件，建议上传文件大小不超过400行500k，您还可以选择下载我们的模板，进行填写 <a
                  @click.stop href="http://zloss.bmxgj.cn/upload/2024-06-26/b59e2d8ca36840f3a247bd5fdd6848de-file"
                  style="color: rgba(24, 144, 255, 1);">【下载模板】</a></p>
            </div>
            <div class="el-upload__text">
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item style="margin-bottom: 30px;" class="formItem" label="补充说明" prop="quality">
          <el-input type="textarea" :rows="3" v-model="enquiryData.quality" placeholder="输入"></el-input>
        </el-form-item>
        <el-row type="flex" style="padding-top: 20px;" :gutter="20">
          <el-col :span="12">
            <el-form-item class="formItem" label="联系人" prop="contacts">
              <el-input v-model="enquiryData.contacts" placeholder="输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item class="formItem" label="联系方式" prop="phone">
              <el-input v-model="enquiryData.phone" placeholder="输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 大图预览 -->
      <div class="model" v-if="imgType" @click="() => { imgType = false }">
        <img :src="dialogImageUrl" alt="" @click="() => { imgType = false }">
      </div>
      <div slot="footer" class="dialog-footer" style="height: 30px;">
        <el-button @click="resetForm('enquiryData')">取 消</el-button>
        <el-button type="primary" @click="submitForm('enquiryData')">提交询价</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { addEnquiryApi, classificationsListApi } from "@/api/index"
import bus from '../EventBus'
let uploadUrl = process.env.VUE_APP_BASE_API
export default {
  data() {
    return {
      headers: {
        'authorization': window.localStorage.getItem('token')
      },
      userCompany: {
        companyName: '',
        companyId: ''
      },// 新增极速询价  用户公司  ID
      typeFn: 0,
      showPictrue: false,
      pictureFileList: [],
      imgType: false,// 图片状态
      uploadUrls: `${uploadUrl}/common/upload`,
      dialogImageUrl: '',
      dialogVisible: false,
      defaultData: {
        value: 'id',
        label: 'name',
        children: 'newChildren',
        expandTrigger: 'hover',
        // 控制级联选择器只选则单个值
        emitPath: false,
        multiple: false,
        checkStrictly: false
      },
      industryList: [],
      paytypeVal: '',
      enquiryType: false,
      enquiryData: {
        companyId: '',
        companyName: '',
        title: "",
        quality: '',
        address: '',
        contacts: '',
        phone: '',
        carrier: '2',
        invoiceType: '3',
        endTime: '',
        deliveryType: '0',
        payMethod: '5',
        subscriptionPer: '',
        accountTime: '',
        productName: '',
        productType: [],
        productUnit: '',
        productNum: '',
        productSpecs: '',
        files: [],
        productModel: '',
      },
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        files: [
          { required: true, message: '请上传文件', trigger: 'change' }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        contacts: [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入联系方式', trigger: 'blur' }
        ],
        carrier: [
          { required: true, message: '请至少选择一个承运方', trigger: 'change' }
        ],
        invoiceType: [
          { required: true, message: '请至少选择一个发票要求', trigger: 'change' }
        ],
        endTime: [
          { required: true, message: '请选择截止时间', trigger: 'change' }
        ],
        payMethod: [
          { required: true, message: '请至少选择一个结算方式', trigger: 'change' }
        ],
        deliveryType: [
          { required: true, message: '请至少选择一个配送方式', trigger: 'change' }
        ],
        productName: [
          { required: true, message: '请输入询价商品名称', trigger: 'blur' }
        ],
        productType: [
          { required: true, message: '请选择商品分类', trigger: 'change' }
        ],
        productUnit: [
          { required: true, message: '请输入商品单位', trigger: 'blur' }
        ],
        productNum: [
          { required: true, message: '请输入商品数量', trigger: 'blur' }
        ],
        productSpecs: [
          { required: false, message: '请输入商品规格', trigger: 'blur' }
        ],
        productModel: [
          { required: false, message: '请输入商品型号', trigger: 'blur' }
        ],
      }
    }
  },
  created() {
    // 获取用户企业id  用于新增极速询价
    let userData = JSON.parse(localStorage.getItem('userInfo'))
    if (userData.companyId == null) {
      return
    } else {
      this.userCompany.companyId = userData.companyId
      this.userCompany.companyName = userData.companyName
    }
  },
  mounted() {
    bus.$on('requestShowDialog', () => {
      this.requestShowDialog()
    })
  },
  methods: {
    //, 询价方式切换
    typeTag(value) {
      this.typeFn = value
      this.enquiryData.files = []
      this.enquiryData.quality = ''
      // this.$refs['enquiryData'].resetFields();
    },
    getTimes(result) {
      let timers = new Date(result)
      let getYear = timers.getFullYear()
      let nowMonth = timers.getMonth() + 1
      let getDate = timers.getDate()
      // 判断月份补0
      if (nowMonth < 10) { nowMonth = '0' + nowMonth }
      // 判断日期补0
      if (getDate < 10) { getDate = '0' + getDate }
      this.enquiryData.endTime = getYear + '-' + nowMonth + '-' + getDate
    },
    handleInput() {
      let value = this.enquiryData.productNum
      // 过滤非数字和小数点
      value = value.replace(/[^\d.]/g, '')
      // 限制小数点后最多两位
      const parts = value.split('.')
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2)
        value = parts.join('.')
      }
      // 更新输入框的值
      this.enquiryData.productNum = value
    },
    scrollToTop() {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }, 200)
    },
    // 支付方式输入框
    payMethodFn(value) {
      console.log(value)
      switch (value) {
        case 1:
          this.paytypeVal = value
          this.enquiryData.subscriptionPer = ''
          break
        case 2:
          this.paytypeVal = value
          this.enquiryData.subscriptionPer = ''
          break
        case 3:
          this.paytypeVal = value
          this.enquiryData.subscriptionPer = ''
          break
        case 4:
          this.paytypeVal = value
          this.enquiryData.accountTime = ''
          break
        case 5:
          this.paytypeVal = value
          this.enquiryData.subscriptionPer = ''
          this.enquiryData.accountTime = ''
          break
        default:
          break
      }
    },
    // 获取全部分类
    async getClassifyList() {
      await classificationsListApi(0).then(res => {
        let newIndustryList = res.data.result
        newIndustryList.forEach((item, index) => {
          if (item.children && item.children.length !== 0) {
            item.newChildren = item.children
            item.children.forEach((item2, index) => {
              if (item2.children && item2.children.length !== 0) {
                item2.newChildren = item2.children
                item2.children.forEach((item3, index) => {
                  if (item3.children && item3.children.length !== 0) {
                    item3.newChildren = item3.children
                  }
                })
              }
            })
          }
        })
        this.industryList = newIndustryList
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let param = {
            files: '',
            quality: '',
            contacts: '',
            companyId: this.userCompany.companyId,
            companyName: this.userCompany.companyName
          }
          // 如果 this.typeFn 为 0，则对 enquiryData 的 endTime 和 files 进行处理
          if (this.typeFn == 0) {
            this.enquiryData.companyId = this.userCompany.companyId
            this.enquiryData.companyName = this.userCompany.companyName
            this.enquiryData.endTime = this.enquiryData.endTime + ' 23:59:59'
            // 确保只对文件数组进行一次 JSON.stringify
            if (typeof this.enquiryData.files === 'string') {
              try {
                this.enquiryData.files = JSON.parse(this.enquiryData.files)
              } catch (e) {
                console.error("无法解析文件中的 JSON 字符串:", e)
              }
            }
            this.enquiryData.files = JSON.stringify(this.enquiryData.files)
            this.enquiryData.type = this.typeFn
          } else {
            // 确保只对文件数组进行一次 JSON.stringify
            if (typeof this.enquiryData.files === 'string') {
              try {
                this.enquiryData.files = JSON.parse(this.enquiryData.files)
              } catch (e) {
                console.error("无法解析文件中的 JSON 字符串:", e)
              }
            }
            param.files = JSON.stringify(this.enquiryData.files)
            param.phone = this.enquiryData.phone
            param.contacts = this.enquiryData.contacts
            param.quality = this.enquiryData.quality === '' ? '' : this.enquiryData.quality
            param.type = 1
          }
          await addEnquiryApi(this.typeFn == 0 ? this.enquiryData : param).then(({ data: res }) => {
            if (res.code === 200) {
              this.$message({
                type: "success",
                message: "发布成功",
                duration: 1000,
              })
              this.$refs['enquiryData'].resetFields()
              this.enquiryData.files = []
              this.pictureFileList = []
              this.enquiryData.phone = ''
              this.enquiryType = false
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          return false
        }
      })
    },

    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.pictureFileList = []
      this.enquiryType = false
    },
    blurInput() {
      this.focusedInput = null
    },
    requestShowDialog() {
      // 子组件中的按钮点击时触发自定义事件，要求父组件显示弹框
      // this.$emit('showDialogRequest');
      this.getClassifyList()
      this.enquiryType = true
      this.typeTag(0)
    },
    // 移除上传完成的文件 事件
    handleRemove(file, fileList) {
      this.enquiryData.files = this.enquiryData.files.filter(item => item.fileName != file.name)
      this.dialogImageUrl = ''
    },
    beforeRemove(file, fileList) {
      if (this.typeFn == 0) {
        return this.$confirm(`确定移除该图片？`)
      } else {
        return this.$confirm(`确定移除该文件？`)
      }
    },
    uploadSuccess(response, file, fileList) {
      if (response.code === 200) {
        this.enquiryData.files.push({
          fileName: file.name,
          url: file.response.result.url,
          type: 3
        })
        this.$message.success('上传成功!')
      } else {
        this.$message.error('上传失败')
      }
    },
    async downloadFile(url, name) {
      try {
        const response = await fetch(url)
        const blob = await response.blob()
        const objectURL = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = objectURL
        link.setAttribute('download', name)
        // 添加到 DOM 并触发点击下载
        document.body.appendChild(link)
        link.click()
        // 下载完成后移除元素并释放资源
        document.body.removeChild(link)
        URL.revokeObjectURL(objectURL)
      } catch (error) {
        console.error('Download failed:', error)
      }
    },
    isImage(file) {
      const imageTypes = ['jpeg', 'png', 'gif']
      return imageTypes.includes(file.name)
    },
    // 点击已上传完成文件 事件
    handlePictureCardPreview(file) {
      this.imgType = true
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleFileCardPreview(file) {
      // 根据文件类型判断是图片还是其他文件
      if (this.isImage(file)) {
        this.imgType = true
        this.dialogImageUrl = file.response.result.url
        this.dialogVisible = true
      } else {
        console.log(file.name, 'a6daw65d1a616')
        this.downloadFile(file.response.result.url, file.name)
      }
    },
    // 文件超出
    changeExceed(files, fileList) {
      if (this.typeFn == 0) {
        if (fileList.length > 0) {
          this.$message.error('最多只能上传三张图片!')
        }
      }
    },
    updatestyle() {
      let a = document.getElementsByClassName('el-picker-panel')
    }
  },
}
</script>

<style scoped lang="less">
.updiv {
  height: 298px;
  background: #FFFFFF;
  border-radius: 16px 16px 16px 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

/deep/.el-upload-dragger {
  width: auto;
}

/deep/.el-upload {
  width: 100%;
}

.header {
  display: flex;
  width: 741px;
  height: 48px;
  position: fixed;
  background-color: #FFFFFF;
  z-index: 999999;

  div {
    width: 50%;
    background: rgba(245, 246, 247, 1);
    font-weight: 600;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 24px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    line-height: 48px;
    text-align: center;
  }

  .on {

    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    font-style: normal;
    text-transform: none;
    background: #1890FF;
  }
}

/deep/ .demo-enquiryData {
  padding-top: 70px;
}

.fastBox {
  /deep/.el-dialog {
    margin-top: 40px !important;
    width: 800px;
    border-radius: 16px;

    .el-dialog__title {
      font-weight: bold;
    }

    .el-dialog__body {
      padding: 0 20px;
      height: 500px;
      overflow-y: auto;
      position: relative;

      .el-input__inner {
        height: 32px;
      }

      .el-cascader {
        line-height: 32px;
      }

      .el-input__icon {
        line-height: 32px;
      }
    }
  }

  .el-form-item {
    margin-bottom: 10px;

    /deep/.el-form-item__label {
      color: black;
      font-weight: bold;
    }

    /deep/.el-form-item__content {
      line-height: 10px;
      margin-left: 0 !important;
    }
  }
}

/deep/.el-upload--picture-card {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
}

.earnest {
  /deep/.el-input__inner {
    padding-right: 23px;
    height: 30px;
    font-weight: bold;
  }

  /deep/.el-input__suffix {
    position: absolute;
    top: 0px;
    text-align: center;
  }
}

/deep/.el-form-item__label {
  line-height: 34px;
  float: none;
}

.formItem {
  /deep/.el-form-item__content {
    margin-left: 0 !important;
    height: 32px;

    .el-radio {
      line-height: 32px;

      span {
        font-weight: bold;
      }
    }
  }
}

.back-to-top-button {
  padding: 12px;
  position: fixed;
  top: 78%;
  right: 40px;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  cursor: pointer;
  transition: opacity 0.3s;
  width: 60px;
  height: 120px;
  font-size: 12px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.60);
  box-shadow: 0px 0px 24px 0px rgba(24, 144, 255, 0.2);
  z-index: 9999 !important;

  >div:nth-child(1) {
    position: relative;
    height: 54px;
    border-bottom: 1px solid rgba(241, 244, 246, 1);

    .robot {
      position: absolute;
      top: -140px;
      right: -20px;
      width: 76px;
      height: 124px;
    }

    p {
      margin-left: -5px;
      text-align: center;
    }
  }

  >div:nth-child(2) {
    height: 48px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;

    img {
      margin: 8px 0;
    }
  }
}

// .back-to-top-button>div:hover {
//   opacity: 0.8;
// }
.model {
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

}
</style>
