const domainDef = process.env.VUE_APP_DOMAIN
/**
 * 设置cookie
 * @param {string} key cookie name
 * @param {string} value cookie value
 * @param {string} domain 主域名
 * @param {number} maxExpired 最大过期时间 秒数
 */
export const setCookie = (key, value, domain = domainDef, maxExpired = 7 * 24 * 60 * 60) => {
  console.log(location.origin)
  if (location.origin.includes(domainDef.slice(1))) {
    const cookie = `${key}=${value};domain=${domain};path=/;max-age=${maxExpired};`
    document.cookie = cookie
  } else {
    const cookie = `${key}=${value};path=/;max-age=${maxExpired};`
    document.cookie = cookie
  }
}


/**
 * 获取cookie
 * @param {string} name cookie name
 * @returns cookie value
 */
export const getCookie = (name) => {
  const cookies = document.cookie.split("; ")
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split("=")
    if (cookie[0] === name) {
      return cookie[1]
    }
  }
  return ""
}
/**
 * 清除cookie 
 * @param {string | string[]} key cookie name
 * @param {string} domain 主域名
 */
export const clearCookie = (key, domain = domainDef) => {
  console.log(Object.prototype.toString.call(key))
  if (Object.prototype.toString.call(key) === '[object String]') {
    document.cookie = `${key}=;max-age=0;path=/;domain=${domain}`
  } else if (Object.prototype.toString.call(key) === '[object Array]') {
    for (let i = 0; i < key.length; i++) {
      document.cookie = `${key[i]}=;max-age=0;path=/;domain=${domain}`
    }
  }
}