export const mixins = {
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    // 清空地址里空数组  递归删除空的三级数据
    removeEmptyChildLists(items) {
      items.forEach((item) => {
        if (item.childList && item.childList.length === 0) {
          delete item.childList // 删除空的childList属性
        } else if (item.childList && item.childList.length > 0) {
          this.removeEmptyChildLists(item.childList) // 递归处理子项
        }
      })
    },
    // 设置选择地址宽度
    setAddressSelectWidth() {
      this.$nextTick(() => {
        let inputEl = document.querySelector('.el-cascader .el-input')
        let fontSize = window
          .getComputedStyle(inputEl)
          .fontSize.replace('px', '')
        let path = this.$refs['cascadery']
          .getCheckedNodes()[0]
          .pathLabels.join('/')
        let widthText = path.length * fontSize
        inputEl.style.width = widthText + 75 + 'px'
      })
    },
    // 重置地址宽度
    resetAddressSelectWidth() {
      this.$nextTick(() => {
        document.querySelector('.el-cascader .el-input').style.width = 75 + 'px'
      })
    },
  },
}
